import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { RichTextRenderer } from '../components/rich-text-renderer'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const SkillsAccordion = () => {
  const data = useStaticQuery(graphql`
    query SkillsAccordionQuery {
      webiny {
        listSkills(sort: createdOn_ASC) {
          data {
            createdOn
            description
            id
            title
            shortDescription
          }
        }
      }
    }
  `)

  const skills = data.webiny.listSkills.data

  return (
    <div className="">
      <Accordion alloallowMultipleExpanded={false} allowZeroExpanded
      className="mt-7 mb-12 border-b border-neutral-300">
      {skills.map(skill => {
              return (
            <AccordionItem
              key={skill.title}
                >
                <AccordionItemHeading
                  className="border-t font-bold border-neutral-300">
                    <AccordionItemState>
                      {({ expanded }) => (expanded ? 
                        <AccordionItemButton
                        className="">
                          <h3 className='text-md lg:text-md py-5 m-0 pr-6 lg:pr-0 relative font-semibold'>
                            {skill.title}
                            <svg class="-rotate-90 float-right absolute right-0 top-7" width="21" height="10" viewBox="0 0 21 10" fill="#000" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.1716 0.146445L20.3536 4.32843C20.5488 4.52369 20.5488 4.84027 20.3536 5.03553L16.1716 9.21751C15.9763 9.41277 15.6597 9.41277 15.4645 9.21751C15.2692 9.02225 15.2692 8.70567 15.4645 8.51041L18.7929 5.18198L4.53023e-07 5.18198L3.656e-07 4.18198L18.7929 4.18198L15.4645 0.853552C15.2692 0.65829 15.2692 0.341708 15.4645 0.146445C15.6597 -0.0488169 15.9763 -0.0488169 16.1716 0.146445Z" fill="#222222"></path></svg>
                          </h3>
                        </AccordionItemButton>
                      :
                        <AccordionItemButton
                        className="">
                          <h3 className='text-md lg:text-md py-5 m-0 pr-6 lg:pr-0 relative font-semibold'>
                            {skill.title}
                            <svg class="rotate-90 float-right absolute right-0 top-7" width="21" height="10" viewBox="0 0 21 10" fill="#000" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.1716 0.146445L20.3536 4.32843C20.5488 4.52369 20.5488 4.84027 20.3536 5.03553L16.1716 9.21751C15.9763 9.41277 15.6597 9.41277 15.4645 9.21751C15.2692 9.02225 15.2692 8.70567 15.4645 8.51041L18.7929 5.18198L4.53023e-07 5.18198L3.656e-07 4.18198L18.7929 4.18198L15.4645 0.853552C15.2692 0.65829 15.2692 0.341708 15.4645 0.146445C15.6597 -0.0488169 15.9763 -0.0488169 16.1716 0.146445Z" fill="#222222"></path></svg>
                          </h3>
                        </AccordionItemButton>
                      )}
                    </AccordionItemState>
                
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <RichTextRenderer content={skill.description} />
                </AccordionItemPanel>
            </AccordionItem>
      )
    })}
    </Accordion>
    </div>
  )
}

export default SkillsAccordion